import Atlassian from "../assets/images/partners/atlassian.jpg"
import AWS from "../assets/images/partners/aws.jpg"
import Cast from "../assets/images/partners/cast.jpg"
import Confluent from "../assets/images/partners/confluent.jpg"
import Databricks from "../assets/images/partners/databricks.jpg"
import Dynatrace from "../assets/images/partners/dynatrace.jpg"
import Evolveware from "../assets/images/partners/evolveware.jpg"
import Fossa from "../assets/images/partners/fossa.jpg"
import GoogleCloud from "../assets/images/partners/cloudplatform.jpg"
import Microsoft from "../assets/images/partners/microsoft.jpg"
import Opentext from "../assets/images/partners/opentext.jpg"
import Oracle from "../assets/images/partners/oracle.jpg"
import Pega from "../assets/images/partners/pega.jpg"
import Redhat from "../assets/images/partners/redhat.jpg"
import Tableau from "../assets/images/partners/tableau.jpg"
import UIPath from "../assets/images/partners/uipath.jpg"

export const data = [
  {
    title: "Atlassian",
    desc: "",
    image: Atlassian
  },
  {
    title: "AWS",
    desc: "",
    image: AWS,
    category: "Strategic"
  },
  {
    title: "Cast",
    desc: "",
    image: Cast,
    category: "Services"
  },
  {
    title: "Confluent",
    desc: "",
    image: Confluent,
    category: "IRAD"
  },
  {
    title: "Databricks",
    desc: "",
    image: Databricks,
    category: "IRAD"
  },
  {
    title: "Dynatrace",
    desc: "",
    image: Dynatrace,
    category: "Services"
  },
  {
    title: "Evolveware",
    desc: "",
    image: Evolveware,
    category: "IRAD"
  },
  {
    title: "Fossa",
    desc: "",
    image: Fossa,
    category: "Services"
  },
  {
    title: "Google Cloud Platform",
    desc: "",
    image: GoogleCloud,
    category: "Strategic"
  },
  {
    title: "Microsoft",
    desc: "",
    image: Microsoft,
    category: "Strategic"
  },
  {
    title: "Opentext",
    desc: "",
    image: Opentext,
    category: "Services"
  },
  {
    title: "Oracle",
    desc: "",
    image: Oracle,
    category: "Strategic"
  },
  {
    title: "Pega",
    desc: "",
    image: Pega,
    category: "Services"
  },
  {
    title: "Redhat",
    desc: "",
    image: Redhat,
    category: "Strategic"
  },
  {
    title: "Tableau",
    desc: "",
    image: Tableau,
    category: "Strategic"
  },
  {
    title: "UI Path",
    desc: "",
    image: UIPath,
    category: "Services"
  }

]

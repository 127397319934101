import { Typography, Grid } from "@material-ui/core";

export default function investmentsContent(props) {
  return (
    <div className="investmentsContentContainer">
      {props.content.map((data) => {
        return (
          <Grid
            container
            xs={12}
            sm={11}
            md={10}
            lg={8}
            spacing={3}
            className="container"
          >
            <Grid item xs={12} className="marginCenter">
              <Typography variant="body1" className="title">
                {data.title}
              </Typography>
              <div className="spacer" />
              <img src={data.image} />
            </Grid>
            <div className="spacer" />
            <div className="dividerShape" />
          </Grid>
        );
      })}
    </div>
  );
}

import { Typography } from "@material-ui/core";
import React from "react";
import AccordionComponent from "../components/accordion";

export default function faqContent(props) {
  return (
    <div className="faqContentContainer">
      <div className="titleLine" />
      <Typography className="category">DevSecOps</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "devsecops") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />
      <Typography className="category">Agile</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "agile") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />

      <Typography className="category">AppMod</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "appmod") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                category={data.category}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

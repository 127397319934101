import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";

export default function offeringsContent(props) {
  return (
    <div className="offeringsContentContainer">
      <Button
        href="https://saiconline.sharepoint.us/teams/SoftwareOfferingArtifacts/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FSoftwareOfferingArtifacts%2FShared%20Documents%2FGeneral&viewid=4aa3f6b6%2D1fd3%2D4534%2D9d17%2Dabbed57ceab4 "
        target="_blank"
        style={{
          fontSize: "18px",
          textAlign: "center",
          width: "325px",
          height: "50px",
          padding: "10px",
          color: "#ffffff",
          background: "#006bb6",
        }}
      >
        Document Repository
      </Button>

      <div className="spacer" />
      <div className="spacer" />

      {props.content.map((data) => {
        return (
          <Grid container xs={12} spacing={3}>
            <Grid item xs={12}>
              <a
                href={data.link}
                className="linkBox"
                target="_blank"
                rel="noreferrer"
                alt={data.title}
              >
                <Typography variant="h5">{data.title}</Typography>
              </a>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{data.description}</Typography>
              <div className="spacer" />

              <Typography variant="body2">{data.subLink}</Typography>

              <br />
              <Typography variant="body2">{data.subLink2}</Typography>

              <br />
              <Typography variant="body2">{data.subLink3}</Typography>
            </Grid>
            <div className="spacer" />
            <div className="dividerShape" />
            <div className="spacer" />
          </Grid>
        );
      })}
    </div>
  );
}

import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

import {
  AppBar,
  Toolbar,
  Grid,
  Button
} from '@material-ui/core'

import SaicLogo from '../assets/images/Saic-Logo.png'
import NavBar from './navBar'

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
}


export default function ButtonAppBar(props) {  
 
  return (
  <React.Fragment>
         <CssBaseline />
    <div className="headerBar" >
    <ElevationScroll {...props}>
      <AppBar >
        <Toolbar>
              <Grid container xs={12} >
                <Grid item xs={3} sm={3} md={3} lg={2} >
                   <a href={"/"} >
                    <img src={SaicLogo} alt="SAIC LOGO"  />
                   </a>
                  
                  </Grid>
                  <Grid item xs={6} sm={6}  md={6} lg={8}  > 
        
                      <NavBar />

                  </Grid>
                  <Grid item xs={3} sm={3}  md={3} lg={2} >

                    <Button
                      href="https://digital.saicif.com/"
                      className="button"
                      target="_blank"
                    >      
                          <span class="buttonGradient"></span>
                        Contact Us
                      </Button>

          
                </Grid>
              </Grid>

        </Toolbar>
      </AppBar>
      </ElevationScroll>
      
    </div>
    </React.Fragment>
      
       
  )

}




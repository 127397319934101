import React from "react";
import { 
    Typography,
    Grid,
    Button,
    ButtonGroup
} from "@material-ui/core"
import HOME_BANNER from "../assets/images/APP_DEV_HOME_BANNER.jpg"
import AboutAIJson from "../data/aboutAI.json"

export default function AboutAI() {
  const [state] = React.useState({
    dataValues: AboutAIJson,
  });

  return (
    <div className="aboutAIContainer">
      <Grid container xs={12} className="banner">

        <Grid item xs={12} sm={12}>

          <Typography className="aboutTitle fadePanel">
            <b><i className="line">Application Development</i></b>{" "}
          </Typography>

          <img src={HOME_BANNER} alt="Home Banner" />

        </Grid>

      
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12} className="homeButtons"  >
        <Grid item xs={12}  sm={4} md={4} lg={3} className="marginCenter" >

            <ButtonGroup variant="contained">
              <Button 
              target="_blank"
              variant="contained"
              href="https://saiconline.sharepoint.us/:w:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/AppDev%20IFT%20Offering%20Guide.docx?d=wf87f4efe96ba4adcb99514d2c23bee07&csf=1&web=1&e=rbrE95"
              >
                Offering Guide 
                </Button>
            
              <Button
                variant="contained"
                target="_blank"
                href="https://saiconline.sharepoint.us/teams/SoftwareOfferingArtifacts/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=MbwvrC&cid=9f7bbb9e%2D23cf%2D4377%2Daf3a%2Dd2e2bc74853f&RootFolder=%2Fteams%2FSoftwareOfferingArtifacts%2FShared%20Documents%2FGeneral%2F101%20%28Business%20Documents%29%2FCustomer%20%26%20Training%20Briefs&FolderCTID=0x012000EE2B5BDCD6FA2041A82F7B3C4623006D"
              >
                Customer Brief
              </Button>
            </ButtonGroup>
           
          </Grid>
        
      </Grid>

    </div>
  )
}

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { data } from "../data/devSecOpsOfferings";

export default function DevSecOps() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="approachAIContainer">
          <div className="dividerShape" />
      <Grid container xs={12} sm={10} md={8} lg={12} className="container">
        {state.dataValues.map((item) => {
          return (
            <Grid item xs={12} sm={12} md={12} lg={4} className="columns">
              <a
                href={item.link}
                className="linkBox"
                target="_blank"
                rel="noreferrer"
                alt={item.title}
              >
                <img src={item.image} alt="DevSecOps Image" />
                <div className="spacer" />
                <Typography className="title">{item.title}</Typography>
              </a>
              <div className="spacer" />
              <Typography>{item.description}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

import React from "react"
import AwsExportConfig from "./aws-exports"
import { Auth, Amplify } from "aws-amplify"
import Transition from "./components/transition"

Amplify.configure(AwsExportConfig())

class AuthorizeApp extends React.Component {

  state = {
    isReloading: false,
  }

  componentDidMount() {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser()
        console.log("Authorization: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");
        this.setState({
          isReloading: true,
        })

        setTimeout(() => {
      
        Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    }

    getUser()
  }

  transferPage = () => {
    window.location = '/transition'
  }

  render() {
    return (
     <div>
        {this.state.isReloading === true ? <div style={{ margin: '0 auto' }}  ><Transition /></div> :
        this.props.children

        }
      </div>
    )
  }
}

export default AuthorizeApp

import CDP_ROADMAP from "../assets/images/investments/CDP_Roadmap.jpg"
import ESF_ROADMAP from "../assets/images/investments/ESF_Roadmap.jpg"
import HA_ROADMAP from "../assets/images/investments/HA-Roadmap.jpg"
import milestone from "../assets/images/investments/milestone.png"

export const data = [
  {
    title: "DevSecOps/ESF: ESF/ Innovation Factory Stack",
    image: ESF_ROADMAP,
  },
  {
    title: "Hyperautomation",
    image: HA_ROADMAP,
  },
  {
    title: "Cloud Native: CloudScend Data Platform ",
    image: CDP_ROADMAP,
  },
  {
    title: "AppMod Roadmap",
    image: milestone,
  }
];

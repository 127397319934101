import React from 'react'
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core/'

import {
    MENU_ICON,
    MENU_UP_ARROW
} from '../assets/icons/IconList'
import {TrimWhiteSpace} from '../functions/utils'

import SAIC_LOGO from '../assets/images/Saic-Logo.png'

export default function DrawerComponent() {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)} 
      className="mobileNav"
    >
         <div className="spacer" />
              <img src={SAIC_LOGO} alt="Saic Logo" className="logo" />
              <div className="dividerShape2" />
             
            <List className="mobileNavLinks" >
           
             <ListItem button >
                <ListItemText>
                    <a href="/" >
                      HOME
                    </a>
                  </ListItemText>
              </ListItem>

              <ListItem button >
                <ListItemText>
                    <a href="https://issaic.saic.com/sites/innovationfactory/overview" target="_blank" rel="noreferrer" >
                     ISSAIC
                    </a>
                  </ListItemText>
              </ListItem>

              <ListItem button >
                <ListItemText  >
                  <a href="/offerings" >
                    OFFERINGS
                  </a>
                </ListItemText>
              </ListItem>

              <ListItem button >
                <ListItemText  >
                <a href={`/demos/` + TrimWhiteSpace("AppModScalingColdFusionConversion")} >
                    DEMOS
                  </a>
                </ListItemText>
              </ListItem>

              <ListItem button >
                <ListItemText  >
                  <a href="https://digital.saicif.com/" >
                    DIGITAL
                  </a>
                </ListItemText>
              </ListItem>
              
              <ListItem button >
                <ListItemText  >
                  <a href="/partners" >
                    PARTNERS
                  </a>
                </ListItemText>
              </ListItem>
  
              <ListItem button >
                <ListItemText  >
                  <a href="/faqs" >
                    FAQs
                  </a>
                </ListItemText>
              </ListItem>

              <ListItem button >
                <ListItemText  >
                  <a href="/aboutUs"  >
                      POCs
                  </a>
                </ListItemText>
              </ListItem>

          </List>
          <div className="spacer" />
          <div className="mobileNavCloseMenu" >
            <IconButton >
              <MENU_UP_ARROW
                iconSize={'40px'}
                iconColor={'#ffffff'}
              />
            </IconButton>
          </div>

    </div>
  )

  return (
    <div className="menuHamIcon"  >
      {[ 'top'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton color="inherit" onClick={toggleDrawer(anchor, true)} >
              <MENU_ICON/>
          </IconButton>
          
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

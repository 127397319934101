import React from 'react'
import DrawerComponent from '../components/drawer'
import {
  Grid,
   Table,
   TableRow,
   TableCell,
   TableContainer
} from '@material-ui/core'
import DropMenu from '../components/dropMenu'

import {TrimWhiteSpace} from '../functions/utils'

export default function NavBar() {

  return (
    <div className="navBar" >
      <TableContainer style={{boxShadow: 'none'}}>
    <Table className="desktopNav"    >
      <TableRow >
        <TableCell style={{borderBottom: 'none'}} >
            <a href="/" style={{color: '#fff', fontSize: '18px'}} >
                Home
              </a>
          
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
            <a href="https://issaic.saic.com/sites/innovationfactory/overview" target="_blank" rel="noreferrer" style={{color: '#fff',fontSize: '18px'}} >
              ISSAIC
            </a>
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
            <a href="/offerings" style={{color: '#fff',fontSize: '18px'}} >
              Offerings
            </a>
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
            <a href={`/demos/` + TrimWhiteSpace("AppModScalingColdFusionConversion")} style={{color: '#fff',fontSize: '18px'}} >
              Demos
             </a>
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
          <DropMenu
            menuItem={'Digital'}
            itemOne={'Cyber'}
            itemTwo={'Platforms & Cloud'}
            itemThree={'EIT'}
            itemOneLink={'https://cyber.digital.saicif.com/'}
            itemTwoLink={'https://cloud.digital.saicif.com/'}
            itemThreeLink={'https://cloud.digital.saicif.com/'}
          />
        </TableCell>
        <TableCell style={{borderBottom: 'none'}} >
              <a href="/partners" style={{color: '#fff',fontSize: '18px'}} >
                    Partners
              </a>
        </TableCell>

        <TableCell style={{borderBottom: 'none'}} >
              <a  href="/faqs" style={{color: '#fff',fontSize: '18px'}} >
                 FAQs
              </a>
        </TableCell>

        <TableCell style={{borderBottom: 'none'}} >
             <a  href="/aboutUs"  style={{color: '#fff',fontSize: '18px'}} >
               POCs
              </a>
        </TableCell>

      </TableRow>
    </Table>
    </TableContainer>


      <div className="mobileNavMenu">
        
        <DrawerComponent />
      </div>

  
    </div>

  )
}

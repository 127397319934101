
import RANA_BARZEGAR from "../assets/images/profile/Rana.jpg"
import ROGER_MOON from "../assets/images/profile/RogerMoon.png"
import JOHN_COBLE from "../assets/images/profile/JohnCoble.png"
import JIM_TUSCON from "../assets/images/profile/JimTuson.jpg"
import RANDY_MAURER from "../assets/images/profile/Maurer.jpg"
import VYAS_AKUNDI from "../assets/images/profile/vyasakundi.png"
import ROB_SAMUELS from "../assets/images/profile/R_Samuels.jpg"
import RICK_ORDOWER from "../assets/images/profile/rordower.png"


export const data = [
    {
        position: "Program Management:",
        name: "Rana Barzegar",
        title: "Program Management Sr. Director",
        email: "Rana.S.Barzegar@saic.com ",
        image: RANA_BARZEGAR
    },
    {
        position: "Application Modernization:",
        name: "Roger Moon",
        title: "Solution Director",
        email: "Roger.H.Moon@saic.com",
        image: ROGER_MOON
    },
    {
        position: "DevSecOps/ESF:",
        name: "John Coble",
        title: "Solution Director",
        email: "John.L.Coble@saic.com",
        image: JOHN_COBLE
    },
    {
        position: "Hyperautomation:",
        name: "Jim Tuson",
        title: "Solution Director",
        email: "James.A.Tuscon@SAIC.COM",
        image: JIM_TUSCON
    },
    {
        position: "Agile Delivery Teams:",
        name: "Randy Maurer",
        title: "Solution Director",
        email: "Randall.A.Maurer@saic.com",
        image: RANDY_MAURER
    },
    {
        position: "Cloud Native: ",
        name: "Vyas Akundi",
        title: "Solution Director",
        email: "Vyaseswar.Akundi@saic.com",
        image: VYAS_AKUNDI
    },
    {
        position: "Dojo:",
        name: "Rob Samuels",
        title: "Master Solution Architect",
        email: "Robert.Z.Samuel@saic.com",
        image: ROB_SAMUELS
    },
    {
        position: "Demand Generation Lead:",
        name: "Rick Ordower",
        title: "Solution Director",
        email: "Richard.Ordower@saic.com",
        image: RICK_ORDOWER
    }


]
import AGILE_IMG from "../assets/images/devSecOpsOfferings/Agile-Banner.jpg";
import APP_MOD_IMG from "../assets/images/devSecOpsOfferings/AppMod-Banner.jpg";
import CLOUD_IMG from "../assets/images/devSecOpsOfferings/Cloud-Banner.jpg";
import CPMT_IMG from "../assets/images/devSecOpsOfferings/Cpmt-Banner.jpg";
import DEVSECOPS_IMG from "../assets/images/devSecOpsOfferings/devSecOps-Banner.jpg";
import DOJO_IMG from "../assets/images/devSecOpsOfferings/Dojo-Banner.jpg";
import AUTOMATION_IMG from "../assets/images/devSecOpsOfferings/Automation-Banner.jpg";

export const data = [
  {
    title: "Agile Delivery Teams",
    description:
      "Staffing any new project is always a challenge, and staffing software development projects, given the array of specialized skills and high demand for software talent, can be particularly vexing.  The Digital Application Development Operations has created an organization that provides a highly skilled, efficient, and modern team-based staffing model that we are leveraging to deliver software solutions across the enterprise.  The focus on teams has helped shift the conversation beyond resource staffing, so that we are providing outcomes instead of individuals.  These teams are offerred as a Fixed Unit Price offering to our Customer Groups for support of complex customer software development initiatives.",
    image: AGILE_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20Agile%20Delivery%20Teams?csf=1&web=1&e=JII2XP",
    subLinkTitle: "Delivery Teams by Sprint",
    subLink: (
      <a
        href="https://marketplace.msp.saic.com/pfh-product-families-page/PFH_DevSecOpsDeliveryTeamsByTheSprint_1423"
        target="_blank"
      >
        Delivery Teams by Sprint
      </a>
    ),
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "Application Modernization Offering Family",
    description:
      "SAIC Application Modernization (AppMod) services transform an organization’s unstable, unsecured portfolio of legacy apps into a secure, cloud-ready architecture, able to rapidly adapt to mission, add a continuous stream of value, and reduce total cost of ownership. Software systems in support of the enterprise must continually adapt to a changing environment, but there are major challenges – technological advancement causes the systems to become obsolete; and technical debts accumulated over years cause systems to be difficult to maintain and unable to meet enterprise needs in a timely manner. Our proven AppMod services: Lead our customers to modern software architectures; Liberate our customers’ IT budgets allowing a refocus on mission objectives; and Establish the tools and processes needed to continually modernize so that history does not repeat itself.",
    image: APP_MOD_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20AppMod?csf=1&web=1&e=b0Gaxy",
    subLinkTitle: "Cloud Insight and Cloud Ready",
    subLink: (
      <a
        href="https://marketplace.msp.saic.com/pfh-product-families-page/PFH_CloudInsights_5881"
        target="_blank"
      >
        Cloud Insight and Cloud Ready
      </a>
    ),
    subLink2Title: <a>Foresight</a>,
    subLink2: <a>Foresight</a>,
    subLink3Title: <a>M2 Portal</a>,
    subLink3: <a>M2 Portal</a>,
  },
  {
    title: "Cloud Native",
    description:
      "Cloud Native Software designs for the cloud from the very beginning and maximizes​ the full potential of cloud-based application development. The offerings create well-architected applications that are secure, high-performing, resilient, efficient, and more cost-effective.",
    image: CLOUD_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20Cloud%20Native?csf=1&web=1&e=M14mOP",
    subLinkTitle: "Cloud Data Platform",
    subLink: <a>Cloud Data Platform</a>,
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "Customizable Program Management Tool (CPMT)",
    description:
      "Cloud-native, modern tool to empower program agility so PM leaders can efficiently tackle the rising tide of requirements. This serverless architecture is scalable to any size program and specifically designed to be DFARS compliant, FOUO-ready, and FedRAMP High while being hosted securely in GovCloud behind SAIC’s internal firewall.",
    image: CPMT_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20CPMT?csf=1&web=1&e=GoZ46a",
    subLinkTitle: "CMPT",
    subLink: (
      <a
        href="https://marketplace.msp.saic.com/pf-product-families-page/PF_CustomizableProgramManagementTool(CPMT_2474"
        target="_blank"
      >
        CMPT
      </a>
    ),
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "DevSecOps and Software Factory",
    description:
      "DevSecOps has become an industry standard because of its ability to improve software quality and security; it also promotes the organizational cultural change required for its successful practice. The software development practice of integrating code changes together as early and as frequently as possible—in combination with instantiating the packaged codebase in an environment that identically mimics production—drastically reduces the strain felt by IT Operations teams during software delivery. Development, Security, and Operations (DevSecOps), which consists of a collection of principles, practices, and technologies, allows for the delivery of integrated, tested, and secure software at the speed of relevance.  This area highlights industry best practices and specific SAIC whitepapers and experiences highlighting our adoption of these practices.",
    image: DEVSECOPS_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20DevSecOps%20and%20Software%20Factory?csf=1&web=1&e=OAOROA",
    subLinkTitle:
      "Enterprise Software Factory (ESF) and Innovation Factor Stack (IFS)",
    subLink: (
      <a>Enterprise Software Factory (ESF) and Innovation Factor Stack (IFS)</a>
    ),
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "Dojo",
    description:
      "Traditionally, a Japanese Dojo, or “place of the way,” is a space dedicated solely for the teaching and practicing of martial arts. The SAIC Dojo is a training ground for application development and operations excellence. This sophisticated immersion environment is devoted to the practice of training delivery teams to develop adaptive mindsets and adopt a culture of transparency, curiosity, innovation, and grit. The Dojo provides an environment where it is safe to fail and learn from it—it is a learning lab, not a training class. Work groups relearn the art of functioning as a team under the SAIC Dojo philosophy.",
    image: DOJO_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20Dojo?csf=1&web=1&e=umD0ce",
    subLinkTitle: "",
    subLink: "",
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "Hyperautomat​​io​​​n​",
    description:
      "Hyperautomation provides technology insertion, process innovation, data collection and aggregation, knowledge management, document/media exploitation, and robotic process automation. This empowers an organization to mine significant value and seamlessly incorporate automated/assisted decision-making to power its mission forward.",
    image: AUTOMATION_IMG,
    link: "https://saiconline.sharepoint.us/:f:/r/teams/SoftwareOfferingArtifacts/Shared%20Documents/General/101%20(Business%20Documents)/101%20BD%20Hyperautomation?csf=1&web=1&e=VIrdk1",
    subLinkTitle: "BI and RPA Solutions by the Sprint",
    subLink: (
      <a
        href="https://marketplace.msp.saic.com/pfh-product-families-page/PFH_LowCodeSolutionsByTheSprint_3593"
        target="_blank"
      >
        BI and RPA Solutions by the Sprint
      </a>
    ),
    subLink2Title: "MetaSift",
    subLink2: (
      <a
        href="https://marketplace.msp.saic.com/pfh-product-families-page/PFH_MetaSift_7426"
        target="_blank"
      >
        MetaSift
      </a>
    ),
    subLink3Title: "",
    subLink3: "",
  },
];
